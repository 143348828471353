import { IconProps } from '@coldpbc/interfaces';

export const ColdSuppliersNavIcon = (props: IconProps) => {
  return (
    <div className={'pt-[1px] pb-[2px] pr-[2px] pl-[1px]'}>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
        <mask id="path-1-inside-1_2272_5131" fill={props.color || 'white'}>
          <path d="M5.90625 15.0938C6.26883 15.0938 6.5625 14.8001 6.5625 14.4375C6.5625 14.0749 6.26883 13.7812 5.90625 13.7812H3.9375C3.57492 13.7812 3.28125 14.0749 3.28125 14.4375C3.28125 14.8001 3.57492 15.0938 3.9375 15.0938H5.90625Z" />
        </mask>
        <path
          d="M5.90625 15.0938C6.26883 15.0938 6.5625 14.8001 6.5625 14.4375C6.5625 14.0749 6.26883 13.7812 5.90625 13.7812H3.9375C3.57492 13.7812 3.28125 14.0749 3.28125 14.4375C3.28125 14.8001 3.57492 15.0938 3.9375 15.0938H5.90625Z"
          fill={props.color || 'white'}
        />
        <path
          d="M5.90625 17.4271C7.5575 17.4271 8.89583 16.0887 8.89583 14.4375H4.22917C4.22917 13.5114 4.98017 12.7604 5.90625 12.7604V17.4271ZM8.89583 14.4375C8.89583 12.7863 7.5575 11.4479 5.90625 11.4479V16.1146C4.98017 16.1146 4.22917 15.3636 4.22917 14.4375H8.89583ZM5.90625 11.4479H3.9375V16.1146H5.90625V11.4479ZM3.9375 11.4479C2.28625 11.4479 0.947917 12.7863 0.947917 14.4375H5.61458C5.61458 15.3636 4.86358 16.1146 3.9375 16.1146V11.4479ZM0.947917 14.4375C0.947917 16.0887 2.28625 17.4271 3.9375 17.4271V12.7604C4.86358 12.7604 5.61458 13.5114 5.61458 14.4375H0.947917ZM3.9375 17.4271H5.90625V12.7604H3.9375V17.4271Z"
          fill="#E0E1EA"
          mask="url(#path-1-inside-1_2272_5131)"
        />
        <mask id="path-3-inside-2_2272_5131" fill={props.color || 'white'}>
          <path d="M11.1562 14.4375C11.1562 14.8001 10.8626 15.0938 10.5 15.0938H8.53125C8.16867 15.0938 7.875 14.8001 7.875 14.4375C7.875 14.0749 8.16867 13.7812 8.53125 13.7812H10.5C10.8626 13.7812 11.1562 14.0749 11.1562 14.4375Z" />
        </mask>
        <path
          d="M11.1562 14.4375C11.1562 14.8001 10.8626 15.0938 10.5 15.0938H8.53125C8.16867 15.0938 7.875 14.8001 7.875 14.4375C7.875 14.0749 8.16867 13.7812 8.53125 13.7812H10.5C10.8626 13.7812 11.1562 14.0749 11.1562 14.4375Z"
          fill={props.color || 'white'}
        />
        <path
          d="M8.82292 14.4375C8.82292 13.5114 9.57392 12.7604 10.5 12.7604V17.4271C12.1512 17.4271 13.4896 16.0887 13.4896 14.4375H8.82292ZM10.5 12.7604H8.53125V17.4271H10.5V12.7604ZM8.53125 12.7604C9.45733 12.7604 10.2083 13.5114 10.2083 14.4375H5.54167C5.54167 16.0887 6.88 17.4271 8.53125 17.4271V12.7604ZM10.2083 14.4375C10.2083 15.3636 9.45733 16.1146 8.53125 16.1146V11.4479C6.88 11.4479 5.54167 12.7863 5.54167 14.4375H10.2083ZM8.53125 16.1146H10.5V11.4479H8.53125V16.1146ZM10.5 16.1146C9.57392 16.1146 8.82292 15.3636 8.82292 14.4375H13.4896C13.4896 12.7863 12.1512 11.4479 10.5 11.4479V16.1146Z"
          fill="#E0E1EA"
          mask="url(#path-3-inside-2_2272_5131)"
        />
        <mask id="path-5-inside-3_2272_5131" fill={props.color || 'white'}>
          <path d="M15.0938 15.0938C15.4563 15.0938 15.75 14.8001 15.75 14.4375C15.75 14.0749 15.4563 13.7812 15.0938 13.7812H13.125C12.7624 13.7812 12.4688 14.0749 12.4688 14.4375C12.4688 14.8001 12.7624 15.0938 13.125 15.0938H15.0938Z" />
        </mask>
        <path
          d="M15.0938 15.0938C15.4563 15.0938 15.75 14.8001 15.75 14.4375C15.75 14.0749 15.4563 13.7812 15.0938 13.7812H13.125C12.7624 13.7812 12.4688 14.0749 12.4688 14.4375C12.4688 14.8001 12.7624 15.0938 13.125 15.0938H15.0938Z"
          fill={props.color || 'white'}
        />
        <path
          d="M15.0938 17.4271C16.745 17.4271 18.0833 16.0887 18.0833 14.4375H13.4167C13.4167 13.5114 14.1677 12.7604 15.0938 12.7604V17.4271ZM18.0833 14.4375C18.0833 12.7863 16.745 11.4479 15.0938 11.4479V16.1146C14.1677 16.1146 13.4167 15.3636 13.4167 14.4375H18.0833ZM15.0938 11.4479H13.125V16.1146H15.0938V11.4479ZM13.125 11.4479C11.4738 11.4479 10.1354 12.7863 10.1354 14.4375H14.8021C14.8021 15.3636 14.0511 16.1146 13.125 16.1146V11.4479ZM10.1354 14.4375C10.1354 16.0887 11.4738 17.4271 13.125 17.4271V12.7604C14.0511 12.7604 14.8021 13.5114 14.8021 14.4375H10.1354ZM13.125 17.4271H15.0938V12.7604H13.125V17.4271Z"
          fill="#E0E1EA"
          mask="url(#path-5-inside-3_2272_5131)"
        />
        <mask id="path-7-inside-4_2272_5131" fill={props.color || 'white'}>
          <path d="M5.90625 17.7188C6.26883 17.7188 6.5625 17.4251 6.5625 17.0625C6.5625 16.6999 6.26883 16.4062 5.90625 16.4062H3.9375C3.57492 16.4062 3.28125 16.6999 3.28125 17.0625C3.28125 17.4251 3.57492 17.7188 3.9375 17.7188H5.90625Z" />
        </mask>
        <path
          d="M5.90625 17.7188C6.26883 17.7188 6.5625 17.4251 6.5625 17.0625C6.5625 16.6999 6.26883 16.4062 5.90625 16.4062H3.9375C3.57492 16.4062 3.28125 16.6999 3.28125 17.0625C3.28125 17.4251 3.57492 17.7188 3.9375 17.7188H5.90625Z"
          fill={props.color || 'white'}
        />
        <path
          d="M5.90625 20.0521C7.5575 20.0521 8.89583 18.7137 8.89583 17.0625H4.22917C4.22917 16.1364 4.98017 15.3854 5.90625 15.3854V20.0521ZM8.89583 17.0625C8.89583 15.4113 7.5575 14.0729 5.90625 14.0729V18.7396C4.98017 18.7396 4.22917 17.9886 4.22917 17.0625H8.89583ZM5.90625 14.0729H3.9375V18.7396H5.90625V14.0729ZM3.9375 14.0729C2.28625 14.0729 0.947917 15.4113 0.947917 17.0625H5.61458C5.61458 17.9886 4.86358 18.7396 3.9375 18.7396V14.0729ZM0.947917 17.0625C0.947917 18.7137 2.28625 20.0521 3.9375 20.0521V15.3854C4.86358 15.3854 5.61458 16.1364 5.61458 17.0625H0.947917ZM3.9375 20.0521H5.90625V15.3854H3.9375V20.0521Z"
          fill="#E0E1EA"
          mask="url(#path-7-inside-4_2272_5131)"
        />
        <mask id="path-9-inside-5_2272_5131" fill={props.color || 'white'}>
          <path d="M11.1562 17.0625C11.1562 17.4251 10.8626 17.7188 10.5 17.7188H8.53125C8.16867 17.7188 7.875 17.4251 7.875 17.0625C7.875 16.6999 8.16867 16.4062 8.53125 16.4062H10.5C10.8626 16.4062 11.1562 16.6999 11.1562 17.0625Z" />
        </mask>
        <path
          d="M11.1562 17.0625C11.1562 17.4251 10.8626 17.7188 10.5 17.7188H8.53125C8.16867 17.7188 7.875 17.4251 7.875 17.0625C7.875 16.6999 8.16867 16.4062 8.53125 16.4062H10.5C10.8626 16.4062 11.1562 16.6999 11.1562 17.0625Z"
          fill={props.color || 'white'}
        />
        <path
          d="M8.82292 17.0625C8.82292 16.1364 9.57392 15.3854 10.5 15.3854V20.0521C12.1512 20.0521 13.4896 18.7137 13.4896 17.0625H8.82292ZM10.5 15.3854H8.53125V20.0521H10.5V15.3854ZM8.53125 15.3854C9.45733 15.3854 10.2083 16.1364 10.2083 17.0625H5.54167C5.54167 18.7137 6.88 20.0521 8.53125 20.0521V15.3854ZM10.2083 17.0625C10.2083 17.9886 9.45733 18.7396 8.53125 18.7396V14.0729C6.88 14.0729 5.54167 15.4113 5.54167 17.0625H10.2083ZM8.53125 18.7396H10.5V14.0729H8.53125V18.7396ZM10.5 18.7396C9.57392 18.7396 8.82292 17.9886 8.82292 17.0625H13.4896C13.4896 15.4113 12.1512 14.0729 10.5 14.0729V18.7396Z"
          fill="#E0E1EA"
          mask="url(#path-9-inside-5_2272_5131)"
        />
        <path
          d="M15.0938 17.7188C15.4563 17.7188 15.75 17.4251 15.75 17.0625C15.75 16.6999 15.4563 16.4062 15.0938 16.4062H13.125C12.7624 16.4062 12.4688 16.6999 12.4688 17.0625C12.4688 17.4251 12.7624 17.7188 13.125 17.7188H15.0938Z"
          fill={props.color || 'white'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.70702 4.59375C2.36086 4.59375 2.07373 4.86281 2.05242 5.20898L1.68 11.1562H1.3125C0.949917 11.1562 0.65625 11.4498 0.65625 11.8124V19.6874C0.65625 20.05 0.949917 20.3437 1.3125 20.3437H19.6875C20.0501 20.3437 20.3438 20.05 20.3438 19.6874V8.53118C20.3438 8.1686 20.0501 7.87493 19.6875 7.87493H12.4688C12.1062 7.87493 11.8125 8.1686 11.8125 8.53118V11.1562H11.1169L10.7445 5.20898C10.7231 4.86281 10.436 4.59375 10.0899 4.59375H7.62883C7.28266 4.59375 6.99554 4.86281 6.97422 5.20898L6.60181 11.1562H6.19493L5.82251 5.20898C5.80118 4.86281 5.51408 4.59375 5.16791 4.59375H2.70702ZM12.4687 12.4688C12.8313 12.4688 13.1249 12.1751 13.1249 11.8125V9.1875H19.0312V13.7812H17.7187C17.3561 13.7812 17.0624 14.0749 17.0624 14.4375C17.0624 14.8001 17.3561 15.0938 17.7187 15.0938H19.0312V16.4062H17.7187C17.3561 16.4062 17.0624 16.6999 17.0624 17.0625C17.0624 17.4251 17.3561 17.7188 17.7187 17.7188H19.0312V19.0312H1.9687V12.4688H12.4687ZM9.47287 5.90625L9.801 11.1562H7.91765L8.24577 5.90625H9.47287ZM4.87912 11.1562L4.551 5.90625H3.32381L2.99569 11.1562H4.87912Z"
          fill={props.color || 'white'}
        />
      </svg>
    </div>
  );
};
