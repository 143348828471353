import {IconProps} from "@coldpbc/interfaces";

export const ColdQuestionnaireIcon = (props: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.74867 4.50086C5.51516 4.50086 4.50092 5.51512 4.50092 6.74861V18.7516C4.50092 19.9851 5.51517 20.9994 6.74867 20.9994H17.2512C18.4847 20.9994 19.499 19.9851 19.499 18.7516V6.74861C19.499 5.51504 18.4847 4.50086 17.2512 4.50086H15.7509C15.5519 4.50004 15.3608 4.57838 15.2196 4.71854C15.0784 4.85876 14.9986 5.04931 14.9979 5.24828C14.9972 5.4482 15.0763 5.64016 15.2176 5.78155C15.359 5.92293 15.551 6.00197 15.7509 6.00122H17.2512C17.6797 6.00122 17.9987 6.32015 17.9987 6.74864V18.7516C17.9987 19.1801 17.6797 19.4991 17.2512 19.4991H6.74868C6.32019 19.4991 6.00126 19.1801 6.00126 18.7516V6.74864C6.00126 6.32015 6.32019 6.00122 6.74868 6.00122H8.24898C8.4489 6.00198 8.64092 5.92294 8.78231 5.78155C8.92363 5.64016 9.00273 5.4482 9.00198 5.24828C9.00128 5.04929 8.92153 4.85875 8.78033 4.71854C8.63911 4.57838 8.44799 4.50005 8.249 4.50086H6.74867Z"
        fill={props.color || "white"} />
      <path
        d="M9.74942 3.00049C8.51591 3.00049 7.50167 4.01474 7.50167 5.24824C7.50167 6.48173 8.51592 7.50154 9.74942 7.50154H14.2505C15.484 7.50154 16.4982 6.48178 16.4982 5.24824C16.4982 4.01473 15.484 3.00049 14.2505 3.00049H9.74942ZM9.74942 4.50079H14.2505C14.679 4.50079 14.9979 4.81979 14.9979 5.24828C14.9979 5.67671 14.679 6.00113 14.2505 6.00113H9.74942C9.32093 6.00113 9.00198 5.67671 9.00198 5.24828C9.00198 4.81985 9.32093 4.50079 9.74942 4.50079Z"
        fill={props.color || "white"} />
      <path
        d="M9.44492 11.1778C9.21799 10.9615 8.99087 10.7462 8.76395 10.5301C8.46524 10.2455 7.99274 10.2557 7.70651 10.5527C7.41958 10.8535 7.43206 11.3303 7.73429 11.6157C8.13431 11.9967 8.53017 12.3753 8.93013 12.7563C9.21993 13.0327 9.67568 13.0327 9.96543 12.7563L11.7648 11.0455C12.0671 10.7601 12.0795 10.2833 11.7926 9.98251C11.508 9.68374 11.0354 9.67108 10.8852 9.81271L9.44492 11.1778Z"
        fill={props.color || "white"} />
      <path
        d="M9.45045 15.5682C9.22252 15.3511 8.99184 15.1375 8.7639 14.9205C8.46366 14.6373 7.9911 14.65 7.70646 14.9488C7.42321 15.2489 7.43563 15.7214 7.73423 16.0063C8.13425 16.3872 8.53012 16.7714 8.93008 17.1523C9.21988 17.4287 9.67562 17.4287 9.96538 17.1523L11.7648 15.436C12.067 15.1506 12.0795 14.6738 11.7926 14.373C11.5063 14.0758 11.0338 14.0657 10.8843 14.208L9.45045 15.5682Z"
        fill={props.color || "white"} />
      <path
        d="M14.2506 10.4968C14.0507 10.4961 13.8588 10.5752 13.7174 10.7165C13.576 10.8579 13.4969 11.0499 13.4977 11.2498C13.4984 11.4488 13.5781 11.6394 13.7193 11.7795C13.8606 11.9197 14.0517 11.998 14.2506 11.9973H15.7511C15.949 11.9965 16.1388 11.9176 16.2788 11.7776C16.4188 11.6375 16.4978 11.4478 16.4985 11.2498C16.4993 11.0508 16.421 10.8597 16.2808 10.7185C16.1406 10.5773 15.9501 10.4975 15.7511 10.4968H14.2506Z"
        fill={props.color || "white"} />
      <path
        d="M14.2506 14.9978C14.0507 14.997 13.8588 15.0761 13.7174 15.2175C13.576 15.3589 13.4969 15.5509 13.4977 15.7508C13.4984 15.9498 13.5781 16.1403 13.7193 16.2805C13.8606 16.4206 14.0517 16.499 14.2506 16.4982H15.7511C15.949 16.4975 16.1388 16.4185 16.2788 16.2786C16.4188 16.1385 16.4978 15.9488 16.4985 15.7508C16.4993 15.5518 16.421 15.3607 16.2808 15.2195C16.1406 15.0783 15.9501 14.9985 15.7511 14.9978H14.2506Z"
        fill={props.color || "white"} />
    </svg>
  );
};
