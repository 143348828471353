import { IconProps } from '@coldpbc/interfaces';

export const ColdCalendarCloseIcon = (props: IconProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path
        d="M2.33333 4.66666H11.6667M2.33333 4.66666V9.8001C2.33333 10.4535 2.33333 10.78 2.46049 11.0296C2.57234 11.2491 2.75069 11.4278 2.97021 11.5396C3.21953 11.6667 3.54607 11.6667 4.19819 11.6667H9.80208C10.4542 11.6667 10.7803 11.6667 11.0296 11.5396C11.2491 11.4278 11.4278 11.2491 11.5396 11.0296C11.6667 10.7803 11.6667 10.4542 11.6667 9.80208V4.66666M2.33333 4.66666V4.2001C2.33333 3.54671 2.33333 3.21977 2.46049 2.9702C2.57234 2.75068 2.75069 2.57233 2.97021 2.46048C3.21977 2.33332 3.54671 2.33332 4.20011 2.33332H4.66666M11.6667 4.66666V4.19819C11.6667 3.54607 11.6667 3.21952 11.5396 2.9702C11.4278 2.75068 11.2491 2.57233 11.0296 2.46048C10.78 2.33332 10.4535 2.33332 9.80011 2.33332H9.33333M4.66666 2.33332H9.33333M4.66666 2.33332V1.16666M9.33333 2.33332V1.16666M8.16666 9.33332L7 8.16666M7 8.16666L5.83333 6.99999M7 8.16666L8.16667 6.99999M7 8.16666L5.83333 9.33333"
        stroke={props.color || "white"}
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
