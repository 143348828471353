import { IconProps } from '@coldpbc/interfaces';

export const ColdUnknownIcon = (props: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15ZM11.2351 9.04553L11.5156 8.95202C11.83 8.84721 12.17 8.84721 12.4844 8.95202L12.7649 9.04553C12.9203 9.09731 13.0553 9.20356 13.1433 9.34439C13.2129 9.45577 13.25 9.58515 13.25 9.71466V9.88018C13.25 10.1156 13.0838 10.3184 12.8529 10.3646C11.9209 10.551 11.25 11.3693 11.25 12.3198V12.5C11.25 12.9142 11.5858 13.25 12 13.25C12.4142 13.25 12.75 12.9142 12.75 12.5V12.3198C12.75 12.0843 12.9162 11.8816 13.1471 11.8354C14.0791 11.649 14.75 10.8307 14.75 9.88018V9.71466C14.75 9.3022 14.633 8.89761 14.4153 8.5494C14.1437 8.11485 13.7276 7.78529 13.2393 7.62251L12.9587 7.52899C12.3364 7.32155 11.6636 7.32155 11.0413 7.52899L10.7607 7.62251C10.2735 7.78492 9.85805 8.11202 9.58584 8.54755C9.36637 8.8987 9.25 9.30446 9.25 9.71854V10.1C9.25 10.5142 9.58579 10.85 10 10.85C10.4142 10.85 10.75 10.5142 10.75 10.1V9.71854C10.75 9.58559 10.7874 9.4553 10.8578 9.34255C10.9452 9.20271 11.0786 9.09768 11.2351 9.04553Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
};
